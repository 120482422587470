import React, { Component } from 'react';
import { getProduct } from '../products';

class Footer extends Component {

    render() {

        const hotsiste = process.env.REACT_APP_HOTSITE;

        const product = getProduct();

        return (
            <footer className={`footer ${hotsiste}`}>
                <div className="container">
                    <div className="reminder">
                        <p className="title">LEMBRE-SE: AO PRECISAR DE</p>
                        <p className={`product-name ${hotsiste}`}>{product.title}</p>
                        <p className="text">
                            Conte com a Jatinox. A empresa conta com os profissionais preparados
                            para transformar rapidamente ideias em produtos inovadores.
                            <a href="http://www.jatinox.com.br/cotacao" target="_blank" className="btn-orcamento">SOLICITE UM ORÇAMENTO</a>
                        </p>
                    </div>
                </div>

                <p className="copyright" dangerouslySetInnerHTML={{__html: `Copyright &copy; - Jatinox - <span class="powered-by">Desenvolvido por <a href="http://www.newmove.com.br/" target="_blank" class="icon"></a></span> - Todos os direitos reservados`}}/>

            </footer>
        );
    }
}

export default Footer;
