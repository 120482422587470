import React, { Component } from 'react';
import iconBobina from '../../images/bobinas/icon_bobina.png';

import './Bobinas.scss'

class Bobinas extends Component {

    render() {
        return (
            <section className="product-specifications">
                <div className="container">
                    <div className="box">
                        <h3>ESPECIFICAÇÕES</h3>
                        <div className="specification-list">
                            <div className="specification-list-item left">
                                <div className="icon-wrapper">
                                    <img src={iconBobina} alt="" className="icon"/>
                                    <img src={iconBobina} alt="" className="icon hidden-mobile"/>
                                </div>
                                <div className="text-wrapper">
                                    <div className="text">
                                        <p>Espessuras da Bobina de aço Inox:</p>
                                        <p>Laminados a quente - 2,60 MM a 8,00 MM</p>
                                        <p>Laminados a frio - 0,40 MM a 6,00 MM</p>
                                    </div>
                                    <div className="text">
                                        <p>Larguras da Bobina de aço Inox:</p>
                                        <p>1,000 MM a 2,000 MM (com e sem aparo de bordas)</p>
                                    </div>
                                    <div className="text">
                                        <p>Tipos de Aço da Bobina de aço Inox: 301/LN, 304/L/H/N, 310S, 316L, 317L, 321, 409, 410S, 420, 430, 439, 441, 444, 498, entre outros.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="specification-list-item right">
                                <div className="text">
                                    <p className="title">Acabamentos:</p>
                                    <p>Os materiais são fornecidos com acabamentos superficiais originais de laminação, polidos ou escovados.</p>
                                    <ul>
                                        <li>Laminados a frio - BF - 2B, 2D ou BB</li>
                                        <li>Laminados a quente - BQ - N 1</li>
                                        <li>Escovados - BF/BQ - #80 A #320</li>
                                        <li>Polidos - BF/BQ - #400, #600, #800 (buffing bright) ou #3000 (mirror finish)</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Bobinas;
