import React, { Component } from 'react';
import Barras from './products/Barras';
import Blanques from './products/Blanques';
import Bobinas from './products/Bobinas';
import Cantoneiras from './products/Cantoneiras';
import Chapa from './products/Chapa';
import ChapaGrossa from './products/ChapaGrossa';
import FitaTiras from './products/FitaTiras';
import TubosComCostura from './products/TubosComCostura';
import TubosSemCostura from './products/TubosSemCostura';

class ProductSpecifications extends Component {

    static componentMapping = {
        barras: Barras,
        blanques: Blanques,
        bobinas: Bobinas,
        cantoneiras: Cantoneiras,
        chapa: Chapa,
        'chapa-grossa': ChapaGrossa,
        'fita-tiras': FitaTiras,
        'tubos-com-costura': TubosComCostura,
        'tubos-sem-costura': TubosSemCostura,
    };

    render() {
        const Product = ProductSpecifications.componentMapping[process.env.REACT_APP_HOTSITE];

        return (
            <div className={'product-specifications-wrapper ' + process.env.REACT_APP_HOTSITE}>
                <Product />
            </div>
        );
    }
}

export default ProductSpecifications;
